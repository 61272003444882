import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PreviewCompatibleImage from '../layout/PreviewCompatibleImage';
import { useBlogRollPreviewQuery } from '../../hooks/blog/blogRollPreviewQuery';

const BlogRollPreview = () => {
  const data = useBlogRollPreviewQuery();
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className='px-5 pb-4 bg-white shadow-2xl rounded-3xl'>
      <div className='grid grid-rows-auto divide-y divide-yellow-700 gap-4'>
        {posts &&
          posts.map(({ node: post }) => (
            <div className='h-full py-4' key={post.id}>
              <div className='grid grid-cols-4 gap-8 align-middle items-center'>
                <div className='block col-span-1 w-full'>
                  {post.frontmatter.featuredimage ? (
                    <div className='rounded-3xl'>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : (
                    <div className='bg-gray-700 rounded-3xl'>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: '/img/logo.png',
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className='col-span-3'>
                  <p>
                    <Link
                      className='mt-2 text-4xl font-extrabold tracking-tight text-gray-700 hover:text-yellow-700'
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <span className='block text-base text-yellow-700 font-light tracking-tight justify-end'>
                      Hondo Lane | {post.frontmatter.date}
                    </span>
                  </p>
                  <p className='text-xl text-gray-400 hover:text-gray-700'>
                    {post.excerpt}
                    <br />
                    <br />
                  </p>
                  <Link
                    className='items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-normal bg-white hover:bg-yellow-200 text-yellow-700 border-yellow-700'
                    to={post.fields.slug}
                  >
                    Keep Reading →
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

BlogRollPreview.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default BlogRollPreview;
