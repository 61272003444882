import api from './api';
import {
  GET_FEATURED_SYMBOL_DATA,
  GET_FEATURED_SYMBOL_DATA_ERROR,
} from './types';

// Get symbols for homepage charts
export const getFeaturedSymbolsData = () => async (dispatch) => {
  try {
    const res = await api.get(`/quotes/featured`);
    dispatch({
      type: GET_FEATURED_SYMBOL_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_SYMBOL_DATA_ERROR,
      payload: { msg: 'Error fetching featured symbols' },
    });
  }
};
