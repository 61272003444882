import { useStaticQuery, graphql } from 'gatsby';

export const useBlogRollPreviewQuery = () => {
  const blogData = useStaticQuery(graphql`
    query BlogRollPreviewQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        limit: 3
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return blogData;
};
