import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../status/Spinner';
import {
  VictoryChart,
  VictoryArea,
  VictoryGroup,
  VictoryAxis,
  VictoryScatter,
} from 'victory';

const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

const StockChart = ({ chartData, loading }) => {
  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div>
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient
                  id='homeChartGradient'
                  x1='0%'
                  y1='0%'
                  x2='0%'
                  y2='100%'
                >
                  <stop offset='0%' stopColor='#374151' />
                  <stop offset='100%' stopColor='white' />
                </linearGradient>
              </defs>
            </svg>
            <VictoryChart
              domainPadding={{ x: 0, y: 0 }}
              scale={{ x: 'time', y: 'linear' }}
            >
              <VictoryGroup
                data={
                  chartData.length > 0
                    ? chartData.map((quote) => ({
                        x: quote.TIMESTAMP,
                        y: quote.CLOSE,
                      }))
                    : []
                }
              >
                <VictoryArea
                  style={{
                    data: {
                      fill: 'url(#homeChartGradient)',
                      stroke: '#374151',
                      strokeWidth: 1,
                    },
                    labels: {
                      fill: '#374151',
                      fontSize: 24,
                      fontWeight: 800,
                      fontFamily: 'Helvetica',
                    },
                  }}
                  domain={{
                    y: [
                      0.998 * Math.min(...chartData.map((d) => d.CLOSE)),
                      1.002 * Math.max(...chartData.map((d) => d.CLOSE)),
                    ],
                  }}
                  labels={({ datum, data }) =>
                    datum.x === data[data.length - 1].x
                      ? Math.ceil(datum.y * 100) / 100
                      : ''
                  }
                />
                <VictoryScatter
                  style={{
                    data: {
                      stroke: ({ datum, data }) =>
                        datum.x === data[0].x ? 'black' : 'transparent',
                      strokeWidth: ({ datum, data }) =>
                        datum.x === data[0].x ? 3 : 0,
                      fill: ({ datum, data }) =>
                        datum.x === data[0].x ? '#B45309' : 'transparent',
                      opacity: 0.75,
                    },
                  }}
                  domain={{
                    y: [
                      0.998 * Math.min(...chartData.map((d) => d.CLOSE)),
                      1.002 * Math.max(...chartData.map((d) => d.CLOSE)),
                    ],
                  }}
                  size={6}
                  opacity={0.5}
                />
              </VictoryGroup>
              <VictoryAxis
                crossAxis
                style={{
                  axis: { stroke: '#9CA3AF' },
                  tickLabels: { fill: 'transparent' },
                }}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: { stroke: '#9CA3AF' },
                  tickLabels: {
                    fill: 'transparent',
                    fontSize: 24,
                    fontWeight: 900,
                    fontFamily: 'Roboto',
                    tickCount: 2,
                  },
                }}
              />
            </VictoryChart>
          </div>
          <p className='text-center text-gray-700 font-black text-base'>
            Previous 5 days
          </p>
          <span className='block text-base text-yellow-700 font-light tracking-tight text-center'>
            {chartData.length > 0
              ? `Last updated: ${new Date(
                  chartData[0].ENTRY_DATE * 1000
                ).toLocaleDateString('en-US', dateOptions)}`
              : ''}
          </span>
        </div>
      )}
    </Fragment>
  );
};

StockChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StockChart;
