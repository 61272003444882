import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeaturedSymbolsData } from '../../actions/home';
import StockChart from '../charting/StockChart';
import Spinner from '../status/Spinner';

const FeatureGrid = ({
  getFeaturedSymbolsData,
  home: { chartData, loading },
}) => {
  useEffect(() => {
    getFeaturedSymbolsData();
  }, [getFeaturedSymbolsData]);

  return (
    <Fragment>
      {loading ? (
        <div className='flex py-5 bg-white shadow-2xl rounded-3xl'>
          <Spinner />
        </div>
      ) : (
        <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-6 py-4'>
          {chartData.map((item) => (
            <div
              key={item.title}
              className='py-5 bg-white shadow-2xl rounded-3xl'
            >
              <div className='block justify-center px-4'>
                <span className='block text-center text-gray-700 font-black text-2xl'>
                  {item.title}
                </span>
                <div className='block h-5/6 text-3xl font-extrabold tracking-tight text-gray-700'>
                  <StockChart chartData={item.quotes} loading={loading} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
      source: PropTypes.string,
    })
  ),
  getFeaturedSymbolsData: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getFeaturedSymbolsData })(
  FeatureGrid
);
