import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import FeatureGrid from '../components/layout/FeatureGrid';
import BlogRollPrevew from '../components/blog/BlogRollPreview';

export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  mainpitch,
}) => (
  <div className='flex-grow'>
    <div className='bg-transparent'>
      <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
        <div className='py-6 text-center'>
          <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
            {heading}
          </h1>
          <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
            {title}
          </h2>
          <h3 className='mt-4 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
            {subheading}
          </h3>
        </div>
      </div>
    </div>
    <section className='lg:max-w-7xl mx-auto px-4 mt-4'>
      <div className='mt-4 px-5 py-5 rounded-3xl bg-white bg-opacity-20 border-2 border-yellow-700'>
        <div className='text-4xl font-extrabold tracking-tight text-white'>
          <h1>{mainpitch.title}</h1>
        </div>
        <div>
          <h3 className='mt-2 text-2xl font-normal text-gray-300'>
            {mainpitch.description}
          </h3>
        </div>
        <div className='mx-auto mt-6'>
          <Link
            className='w-full flex text-center justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-xl text-white bg-yellow-700 hover:bg-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
            to='/app'
          >
            explore data analytics platform
          </Link>
        </div>
      </div>
      <div className='mb-8 mt-8 mx-auto'>
        <FeatureGrid />
      </div>
      <div className='mt-8'>
        <h3 className='text-4xl font-extrabold tracking-tight text-white'>
          latest stories
        </h3>
        <BlogRollPrevew />
      </div>
      <div>
        <div className='mx-auto mb-4 mt-8'>
          <Link
            className='w-full flex justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-md text-white hover:text-white bg-yellow-700 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
            to='/blog'
          >
            read more
          </Link>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            source
            text
          }
          heading
          description
        }
      }
    }
  }
`;
